import React from 'react';

const ChatListItem = ({ user, onSelectUser }) => {
    return (
        <div onClick={() => onSelectUser(user)} className="flex items-center justify-between p-5 border-b border-fourth text-textColor cursor-pointer">
            <div className="flex items-center">
                <img src={user.avatar} alt={user.name} className="h-10 w-10 rounded-full mr-3 object-cover" />
                <div className='flex flex-col'>
                    <h5 className="font-bold mr-auto">{user.name} <span className='text-textColor/50 font-semibold'>| {user.company}</span></h5>
                    <p className="text-left text-sm text-textColor/70 mr-auto line-clamp-1">{user.lastMessage}</p>
                </div>
            </div>
            <div className='flex flex-col'>
                <span className="text-sm mx-2">{user.timeSinceLastMessage}</span>
            </div>
        </div>
    );
};

export default ChatListItem;
