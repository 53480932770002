// JobCard.js
import React from 'react';
import TinderCard from 'react-tinder-card';
import { FaMapMarkerAlt } from 'react-icons/fa';

const JobCard = ({ job, onSwipe, onCardLeftScreen, backgroundImageUrl, onInfoClick, style  }) => {

    const handleInfoClick = (e, job) => {
        e.stopPropagation(); // Prevents the click from affecting parent elements
        onInfoClick(job);
    };


    return (
        <TinderCard
            key={job.id}
            onSwipe={(dir) => onSwipe(dir, job.id)} // Use onSwipe from props
            onCardLeftScreen={() => onCardLeftScreen(job.id)} // Use onCardLeftScreen from props
            className="absolute pressable rounded-2xl"
            style={style}
        >
            <div className="rounded-xl shadow-lg flex flex-col justify-between p-4" style={{ width: '320px', height: '550px' }}>
                
                <div className="z-5 absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center rounded-xl" 
                     style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                </div>

                <div className="z-10 absolute bottom-0 left-0 right-0 h-3/4 bg-gradient-to-t from-black to-transparent rounded-xl"></div>
                {/* Top Row */}
                <div className="z-30 flex justify-between items-start">
                    <div className='flex flex-row gap-2'>
                        <p className="flex my-auto badge text-white text-[12px] px-2 py-1 rounded-md m-0.5 font-semibold" 
                            style={{ backgroundColor: 'rgba(75, 85, 99, 0.3)' }}><FaMapMarkerAlt className="flex my-auto mr-1" />{job.location.country}</p>
                        <p className="flex my-auto badge text-white text-[12px] px-2 py-1 rounded-md m-0.5 font-semibold" 
                            style={{ backgroundColor: 'rgba(75, 85, 99, 0.3)' }}>{job.employmentType}</p>
                    </div>
                </div>

                {/* Bottom Row */}
                <div className='z-30 flex flex-col'>
                    <div className="flex flex-row">
                        <div className='flex flex-col justify-between items-start mr-auto'>
                            <h3 className="text-white text-lg font-bold">{job.title}</h3>
                            <p className="text-white text-sm font-semibold">{job.companyName}</p>
                        </div>
                        <button onClick={(e) => handleInfoClick(e, job)} className="flex my-auto text-white text-2xl px-3 py-2 rounded-lg mr-1 pressable" style={{ backgroundColor: 'rgba(75, 85, 99, 0.3)' }}>
                            👁️
                        </button>
                    </div>
                    <div className="flex flex-wrap mt-2">
                        {job.skills.map((skill, index) => (
                            <span key={index} className="badge text-white text-[11px] px-1.5 py-0.5 rounded-md m-0.5" 
                            style={{ backgroundColor: 'rgba(75, 85, 99, 0.2)' }}>{skill}</span>
                        ))}
                    </div>
                </div>
            </div>
        </TinderCard>
    );
};

export default JobCard;
