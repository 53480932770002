import React, { useState, useRef, useEffect } from 'react';
import { IoMenu, IoChevronUp } from 'react-icons/io5';
import { FaYoutube, FaTiktok, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

function LandingPage({ onTryDemo }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const navbar = useRef(null);
    const heroRef = useRef(null);
    const infoRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
    setIsMenuOpen(false); // Close menu after clicking
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="font-sans text-textColor bg-background">
      {/* Navigation Bar */}
      <nav ref={navbar} className="flex justify-between items-center p-4 bg-secondary text-white relative">
        <IoMenu className="text-2xl text-textColor cursor-pointer z-50" onClick={() => setIsMenuOpen(!isMenuOpen)} />
        <img src="/assets/images/zwipe_logo_lightBG.png" alt="Feature" className="w-32" />
        <button 
          onClick={onTryDemo}
          className="px-6 py-2 text-sm font-bold bg-main text-white rounded-md hover:bg-main/80 transition-colors"
        >
          Try Demo
        </button>
        {isMenuOpen && (
            <div className="absolute top-full left-0 bg-secondary shadow-md p-3 z-50">
                <button onClick={() => scrollToRef(heroRef)} className="block text-textColor text-left p-2 hover:bg-gray-100">Home</button>
                <button onClick={() => scrollToRef(infoRef)} className="block text-textColor text-left p-2 hover:bg-gray-100">Info</button>
                <button onClick={() => scrollToRef(aboutRef)} className="block text-textColor text-left p-2 hover:bg-gray-100">About</button>
                <button onClick={() => scrollToRef(contactRef)} className="block text-textColor text-left p-2 hover:bg-gray-100">Contact</button>
            </div>
        )}
      </nav>

      {/* Hero Section */}
      <section ref={heroRef} className="text-center p-10 bg-background text-textColor">
        <h1 className="text-2xl font-bold mb-4">Revolutionizing Your Job Search and Recruitment</h1>
        <p className="text-sm mb-6">Seamlessly connect with opportunities or talent. Swipe right for your next career move or the ideal candidate.</p>
        <img src="/assets/images/hero_section_img.png" alt="Hero" className="mx-auto lg:w-1/2" />
      </section>

      {/* App Information Section */}
      <section ref={infoRef} className="p-10 bg-secondary text-textColor">
        <h2 className="text-2xl font-bold mb-8">Innovative Job Matching</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-8">
            <div className='flex flex-row'>
                <p className="text-[12px] text-left my-auto lg:text-lg">Our platform uses advanced algorithms to match job seekers with their ideal roles. <br/><br/> Swipe through curated listings tailored to your skills and preferences.</p>
                <img src="/assets/images/swipes_hero.png" alt="Feature" className="w-40 h-32 mx-auto mt-4 lg:w-1/4 lg:h-3/4" />
            </div>
            <div className='flex flex-row'>
                <img src="/assets/images/filtering_hero.png" alt="Feature" className="w-40 h-32 mx-auto mt-4 lg:w-1/4 lg:h-3/4" />
                <p className="text-[12px] text-right my-auto lg:text-lg">For employers, we offer a streamlined candidate selection process. <br/><br/> Easily post job listings and filter candidates based on your specific criteria.</p>
            </div>
        </div>
      </section>

      {/* About Us Section */}
      <section ref={aboutRef} className="p-10 bg-background text-textColor">
        <h2 className="text-2xl font-bold mb-4">About Us</h2>
        <p className="text-sm lg:text-lg">We're a team of innovators committed to enhancing your digital experience through our intuitive solutions.</p>
      </section>

      {/* Contact Section */}
      <section ref={contactRef} className="p-10 bg-background-dark text-textColor">
        <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
        <form className="space-y-4">
            <input type="text" placeholder="Your Name" className="w-full p-2 border rounded" required={true}/>
            <input type="email" placeholder="Your Email" className="w-full p-2 border rounded" />
            <textarea placeholder="Your Message!" className="w-full p-2 border rounded" rows="4"></textarea>
            <button type="submit" className="px-6 py-2 bg-main text-white rounded-custom hover:bg-main/80 transition-colors">Send Message</button>
        </form>
      </section>

      <footer className="bg-background-dark text-textColor text-center p-4">
        <p className='text-sm mb-4'>&copy; {new Date().getFullYear()} Zwipe. All Rights Reserved.</p>
        <div className="flex justify-center space-x-6 mt-4 mb-4">
            <a href="path-to-youtube" className="hover:text-main">
                <FaYoutube className="text-2xl text-red-600" />
            </a>
            <a href="path-to-tiktok" className="hover:text-main">
                <FaTiktok className="text-xl text-teal-600" />
            </a>
            <a href="path-to-linkedin" className="hover:text-main">
                <FaLinkedinIn className="text-xl text-blue-600" />
            </a>
            <a href="https://www.instagram.com/zwipe.app/" className="hover:text-main">
                <FaInstagram className="text-xl text-purple-700" />
            </a>
        </div>
      </footer>

      {showScrollTop && (
        <button 
          onClick={() => scrollToRef(navbar)}
          className="fixed bottom-4 right-4 bg-main text-white p-2 rounded-full shadow-lg hover:bg-main/80 transition"
        >
          <IoChevronUp className="text-xl" />
        </button>
      )}
    </div>
  );
}

export default LandingPage;
