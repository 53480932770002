const chatUsers = [
  {
    uuid: "123-456-789",
    name: "Alice Johnson",
    company: "IKEA",
    lastMessage: "Are you free this friday?",
    avatar: "https://hips.hearstapps.com/hmg-prod/images/ai-headshots-645e5d1ca5d4d.png",
    unreadCount: 2,
    timeSinceLastMessage: "15m",
  },
  {
    uuid: "987-654-321",
    name: "Jonas Ek",
    company: "Apple",
    lastMessage: "You: Thanks!",
    avatar: "https://i0.wp.com/digital-photography-school.com/wp-content/uploads/2016/02/Posing-example.jpeg?ssl=1",
    unreadCount: 1,
    timeSinceLastMessage: "2h",
  },
];

export default chatUsers;