import React, { useState } from 'react';
import ChatListItem from '../Components/ChatListItem';
import chatUsers from '../Data/chatUsersMock';

const ChatPage = ({ setCurrentPage, setCurrentUser }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredUsers = searchTerm
        ? chatUsers.filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.company.toLowerCase().includes(searchTerm.toLowerCase()))
        : chatUsers;

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const selectChatUser = (user) => {
        setCurrentUser(user);
        setCurrentPage('chatView');
    };

    return (
        <div className="flex flex-col h-screen bg-background overflow-auto">
            {/* Search Bar */}
            <div className="p-4">
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 bg-second rounded text-black"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            
            {/* Chat List */}
            <div className="flex flex-col gap-1">
                {filteredUsers.map(user => (
                    <ChatListItem key={user.id} user={user} onSelectUser={() => selectChatUser(user)} />
                ))}
            </div>
        </div>
    );
};

export default ChatPage;
