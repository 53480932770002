// JobInfoPage.js
import React from 'react';
import { useState } from 'react';
import { FaPaperPlane, FaMapMarkerAlt } from 'react-icons/fa'; // Icons for download and message
import { AiOutlineArrowLeft, AiOutlineArrowDown } from 'react-icons/ai'; // Close icon
import Toast from './Toast';

const JobInfoPage = ({ job, onClose, onMessageClick }) => {
    const [toast, setToast] = useState({ message: '', type: '' });

    const showToast = (message, type = "info") => {
        setToast({ message, type });
    };

    const closeToast = () => {
        setToast({ message: '', type: '' });
    };

    return (
        <div className="bg-background">
            {/* Image container with close and download buttons */}
            <div className="relative">
                <img className="w-full h-64 object-cover" src={job.imageUrl} alt={`${job.title}`} />
                <button onClick={onClose} className="absolute m-5 rounded-xl top-0 left-0 text-white text-2xl p-2" style={{ backgroundColor: 'rgba(99, 99, 99, 0.5)' }}><AiOutlineArrowLeft /></button>
                <button className="absolute m-5 rounded-xl top-0 right-0 text-white text-2xl p-2" style={{ backgroundColor: 'rgba(99, 99, 99, 0.5)' }}><AiOutlineArrowDown /></button>
            </div>

            {/* Job title and company name */}
            <div className="flex justify-between items-center mt-6">
                <div className='flex flex-col ml-6'>
                    <h2 className="text-xl text-textColor font-bold mr-auto">{job.title}</h2>
                    <p className="text-md text-textColor/70 text-gray-600 mr-auto">{job.companyName}</p>
                </div>
                <button onClick={() => onMessageClick("Buy premium to use this feature.", "info")} className='p-3.5 bg-main mr-8 rounded-xl'>
                    <FaPaperPlane onClick={() => onMessageClick("Buy premium to use this feature.", "info")} className="text-background text-xl mr-0.5" /> {/* Message icon */}
                </button>
            </div>

            {/* Location and distance */}
            <div className="flex justify-between items-center my-2 mt-6 text-textColor">
                <div className='flex flex-col ml-6'>
                    <p className='mr-auto font-semibold'>Location</p>
                    <p className='mr-auto text-textColor/70 text-sm'>{`${job.location.address}`}</p>
                    <p className='mr-auto text-textColor/70 text-sm'>{`${job.location.city}, ${job.location.country}`}</p>
                </div>
                <div className=''>
                    <div className="flex flex-row my-auto badge mr-6 px-3 py-2 rounded-md font-semibold text-sm bg-third text-textColor">
                        <FaMapMarkerAlt className="flex my-auto" />
                        <p className='flex my-auto ml-1 font-bold'>1km</p>
                    </div> {/* Example distance badge */}
                </div>
            </div>

            <div className="flex my-2 mt-6 text-textColor">
                <div className='flex flex-col mx-6'>
                    <p className='mr-auto font-semibold'>Description</p>
                    <p className='mr-auto text-textColor/70 text-sm text-left'>{job.description}</p>
                </div>
            </div>

            <div className="flex my-2 mt-6 text-textColor">
                <div className='flex flex-col mx-6'>
                    <p className='mr-auto font-semibold'>Duties</p>
                    <ul className='list-disc list-inside text-textColor/70 text-sm text-left'>
                        {job.duties.map((duty, index) => (
                            <li key={index}>{duty}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="flex my-2 mt-6 text-textColor">
                <div className='flex flex-col mx-6'>
                    <p className='mr-auto font-semibold'>Qualifications</p>
                        <ul className='list-disc list-inside text-textColor/70 text-sm text-left'>
                        {job.qualifications.map((qualification, index) => (
                            <li key={index}>{qualification}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="flex my-2 mt-6 text-textColor">
                <div className='flex flex-col mx-6'>
                    <p className='mr-auto font-semibold'>Skills</p>
                    <div className='flex flex-row gap-2 text-textColor/70 mt-1'>
                        {job.skills.map((skill, index) => (
                            <span key={index} className="badge rounded-md text-sm px-2 py-0.5 border border-textColor/25">{skill}</span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Gallery of images */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 my-2 mx-6 mt-6 mb-32 text-textColor">
                <p className='mr-auto font-semibold'>Gallery</p>
                {job.gallery.map((image, index) => {
                    // Randomize size or set specific spans for different images
                    const spanClasses = index % 5 === 0 ? 'row-span-2 col-span-2' : '';
                    return (
                        <div key={index} className={`overflow-hidden ${spanClasses}`}>
                            <img className="object-cover h-full w-full rounded-lg transition-transform duration-500 hover:scale-105" src={image} alt={`Gallery ${index}`} />
                        </div>
                    );
                })}
                {toast.message && <Toast message={toast.message} type={toast.type} onClose={closeToast} />}
            </div>
        </div>
    );
};

export default JobInfoPage;
