import React, { useState } from 'react';
import JobCard from '../Components/JobCard';
import JobInfoPage from '../Components/JobInfoPage';
import mockedJobs from '../Data/mockedJobListings.json'
import { FaFilter } from 'react-icons/fa6';
import Toast from '../Components/Toast';

const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};


function UserHomePage() {
  // Example jobs data
    const [jobs, setJobs] = useState(shuffleArray([...mockedJobs]).slice(0, 5));
    const [toast, setToast] = useState({ message: '', type: '' });

    const [showJobInfo, setShowJobInfo] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);

    const [lastDirection, setLastDirection] = useState();

    const swiped = (direction, id) => {
        console.log('removing: ' + id);
        setLastDirection(direction);
    };

    const outOfFrame = (id) => {
        console.log(id + ' left the screen!');
        setJobs(prevJobs => {
            const filteredJobs = prevJobs.filter(job => job.id !== id);
            const nextJob = mockedJobs.find(job => !filteredJobs.includes(job));
            return [...filteredJobs, nextJob].slice(-5); // Keep only the last 5 jobs
        });
    };

    const handleInfoClick = (job) => {
        setSelectedJob(job);
        setShowJobInfo(true);
    };

    const handleCloseInfo = () => {
        setShowJobInfo(false);
    };
  
  const handleFilterClick = () => {
    // Filter logic
  };

    const showToast = (message, type = "info") => {
        setToast({ message, type });
    };

    const closeToast = () => {
        setToast({ message: '', type: '' });
    };

  return (
    <div className="flex flex-col h-screen bg-background">
        {showJobInfo && selectedJob ? (
            // Render JobInfoPage only when showJobInfo is true
            <JobInfoPage job={selectedJob} onClose={handleCloseInfo} onMessageClick={showToast} />
        ) : (
            // Otherwise, render the regular page content
            <>
                {/* Top Section */}
                <div className="flex items-center justify-center px-12 pt-8 bg-background relative">
                    <div className='flex flex-col'>
                        <h1 className="text-[22px] text-textColor font-bold">Listings</h1>
                        <p className='text-[12px] text-textColor/75'>Malmö, Sweden.</p>
                    </div>
                    <button 
                        onClick={handleFilterClick} 
                        className="px-4 py-2 text-textColor rounded-lg absolute right-16 mb-4"
                    >
                        <FaFilter className='w-5 h-5 text-textColor'/>
                    </button>
                </div>

                {/* Middle Section */}
                {!showJobInfo && (
                    <div className="flex-grow px-8 pb-28 flex justify-center items-center overflow-hidden bg-background shadow-lg">
                        {jobs.map((job, index) => {
                            // Calculate the style for the card based on its position in the array
                            const cardStyle = {
                                transform: `scale(${1 - index * 0.02}) translateY(-${index * 8}px)`,
                                zIndex: jobs.length - index, // Ensures that the top card has the highest z-index
                            };

                            return (
                                <JobCard
                                    key={job.id}
                                    job={job}
                                    onSwipe={swiped}
                                    onCardLeftScreen={outOfFrame}
                                    backgroundImageUrl={job.imageUrl}
                                    onInfoClick={handleInfoClick}
                                    style={cardStyle}
                                />
                            );
                        })}
                    </div>
                )}  
                {toast.message && <Toast message={toast.message} type={toast.type} onClose={closeToast} />}    
            </>
        )}
    </div>
  );
}

export default UserHomePage;
