import React from 'react';
import jobListings from '../Data/mockedJobListings.json'; // Adjust the import path as needed

function MatchesPage({ setCurrentJob, setCurrentPage }) {

    const handleJobSelect = (job) => {
        setCurrentJob(job); // Set the selected job
        setCurrentPage('jobInfoPage'); // Change to the job info page
    };

    return (
        <div className="p-4">
            <div className="flex items-center justify-center px-12 pt-4 bg-background relative mb-8">
                <div className='flex flex-col'>
                    <h1 className="text-[22px] text-textColor font-bold">Matched listings</h1>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {jobListings.map((job) => (
                    <div key={job.id} className="bg-white shadow-md cursor-pointer rounded-lg overflow-hidden">
                        <img src={job.imageUrl} alt={job.title} className="w-full h-48 object-cover" />
                        <div className="p-4">
                            <h2 className="text-[14px] font-semibold text-textColor text-left">{job.title}</h2>
                            <p className="text-[10px] text-gray-700 text-left">{job.companyName}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MatchesPage;
