import React, { useState, useEffect } from 'react';
import './App.css';
import UserHomePage from './Pages/UserHomePage';
import UserPage from './Pages/UserPage'; // Import your UserPage component
import BottomNavigation from './Components/BottomNavigation'; // Import BottomNavigation
import ChatPage from './Pages/ChatPage';
import { IoLogoApple, IoLogoAndroid } from 'react-icons/io5';
import LandingPage from './Pages/LandingPage';
import MatchesPage from './Pages/MatchesPage';
import Toast from './Components/Toast';
import ChatViewPage from './Pages/ChatViewPage';

function App() {
  const [currentPage, setCurrentPage] = useState('landing');
  const [isStandalone, setIsStandalone] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [selectedOS, setSelectedOS] = useState(null);
  const [toast, setToast] = useState({ message: '', type: '' });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const isIOSStandalone = window.navigator.standalone;
    const isAndroidStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (isIOSStandalone || isAndroidStandalone) {
      setIsStandalone(true);
      setCurrentPage('home');
    }
  }, []);

  useEffect(() => {
    if (currentPage !== 'landing') { // Only check for standalone mode if not on the landing page
      const isIOSStandalone = window.navigator.standalone;
      const isAndroidStandalone = window.matchMedia('(display-mode: standalone)').matches;
      setIsStandalone(isIOSStandalone || isAndroidStandalone);
    }
  }, [currentPage]);

  const handleTryDemo = () => {
    setCurrentPage('home');
  };

  if (!isStandalone && currentPage === 'landing') {
    return <LandingPage onTryDemo={handleTryDemo} />;
  }

  const showToast = (message, type = "info") => {
      setToast({ message, type });
  };

  const closeToast = () => {
      setToast({ message: '', type: '' });
  };

  const renderInstructions = (os) => {
    if (os === 'iOS') {
      return (
        <div className="mt-4 mx-4 p-5 border border-gray-200 rounded-lg shadow">
          <p className="text-md font-semibold">Add app to homescreen (iOS):</p>
          <ol className="list-decimal list-inside ml-2 mt-4">
            <li className='my-2'>Tap the 'Share' button in Safari's toolbar (the rectangle with an arrow pointing upward).</li>
            <li className='my-2'>Scroll down and tap 'Add to Home Screen'.</li>
            <li className='my-2'>Safari will now add it to your home screen.</li>
            <li className='my-2'>Go to Home screen, and open Zwipe from there.</li>
          </ol>
        </div>
      );
    } else if (os === 'Android') {
      return (
        <div className="mt-4 mx-4 p-5 border border-gray-200 rounded-lg shadow">
          <p className="text-md font-semibold">Add app to homescreen (Android):</p>
          <ol className="list-decimal list-inside ml-2 mt-4">
            <li className='my-2'>Tap the menu button (three dots in the upper right corner).</li>
            <li className='my-2'>Tap 'Add to Home screen'.</li>
            <li className='my-2'>Browser will now add it to your home screen.</li>
            <li className='my-2'>Go to Home screen, and open Zwipe from there.</li>
          </ol>
        </div>
      );
    }
  };  

  if (currentPage !== 'landing' && !isStandalone) {
    return (
      <div className="flex flex-col justify-center items-center h-full p-4">
        <p className="text-md text-center font-bold mb-4 mt-10 mx-auto">For the best experience, please use application in standalone mode.</p>
        <p className="text-sm text-center mb-8">Select your device's operating <br/> system for instructions:</p>
        <div className="flex space-x-4 mb-6">
          <button 
            onClick={() => { setShowInstructions(true); setSelectedOS('iOS'); }}
            className="flex items-center px-4 py-2 bg-main/75 text-white rounded shadow hover:bg-main transition-colors"
          >
            <IoLogoApple className="mr-2" /> iOS
          </button>
          <button 
            onClick={() => { setShowInstructions(true); setSelectedOS('Android'); }}
            className="flex items-center px-4 py-2 bg-main/75 text-white rounded shadow hover:bg-main transition-colors"
          >
            <IoLogoAndroid className="mr-2" /> Android
          </button>
        </div>
        {showInstructions && renderInstructions(selectedOS)}
      </div>
    );
  }

  let ComponentToRender;
  switch (currentPage) {
    case 'landing':
      ComponentToRender = <LandingPage onTryDemo={handleTryDemo} />;
      break;
    case 'home':
      ComponentToRender = <UserHomePage />;
      break;
    case 'user':
      ComponentToRender = <UserPage />;
      break;
    case 'chat':
      ComponentToRender = <ChatPage setCurrentPage={setCurrentPage} setCurrentUser={setCurrentUser} />;
      break;
    case 'matches':
      ComponentToRender = <MatchesPage />;
      break;
    case 'chatView':
      ComponentToRender = <ChatViewPage currentUser={currentUser} goBack={() => setCurrentPage('chat')} />;
      break;
    default:
      ComponentToRender = <LandingPage onTryDemo={handleTryDemo} />;
  }

  return (
    <div className="App">
      {ComponentToRender}
      {currentPage !== 'landing' && (
        <BottomNavigation setCurrentPage={setCurrentPage} currentPage={currentPage} />
      )}
      {toast.message && <Toast message={toast.message} type={toast.type} onClose={closeToast} />}
    </div>
  );
}

export default App;