
const Toast = ({ message, type, onClose }) => {
    let bgColorClass = '';
    switch (type) {
        case 'success': bgColorClass = 'bg-green-500'; break;
        case 'error': bgColorClass = 'bg-red-500'; break;
        case 'info': bgColorClass = 'bg-blue-500'; break;
        default: bgColorClass = 'bg-gray-500';
    }

    return (
        <div className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded shadow-lg text-white ${bgColorClass}`}>
            <span>{message}</span>
            <button onClick={onClose} className="text-lg ml-4">✕</button>
        </div>
    );
};

export default Toast;
