const chatMessages = {
  "123-456-789": [
    {
      text: "Hey Alice, glad to match with the job application!",
      sender: "me",
      timestamp: new Date("2024-02-07T09:30:00Z").toISOString(),
    },
    {
      text: "Hi! Yeah I really think you'd do this job perfectly, are you open for an interview - say tomorrow?",
      sender: "other",
      timestamp: new Date("2024-02-07T09:45:00Z").toISOString(),
    },
    {
      text: "Absolutely! Send me an invite and we'll talk.",
      sender: "me",
      timestamp: new Date("2024-02-07T09:46:00Z").toISOString(),
    },
    {
      text: "Awesome! Talk to you later.",
      sender: "other",
      timestamp: new Date("2024-02-07T09:47:00Z").toISOString(),
    },
  ],
  "987-654-321": [
    {
        text: "Hey there Mathias! We at Apple really think you'd be a good fit for our new position as a 'Software Engineer'. Could you please send over your resumé?",
        sender: "other",
        timestamp: new Date("2024-02-06T14:27:00Z").toISOString(),
    },
    {
      text: "Hi Jonas, glad to hear that! Of course, I'll send it over immediately.",
      sender: "me",
      timestamp: new Date("2024-02-06T14:30:00Z").toISOString(),
    },
    {
      text: "Thanks! I'll review them by tomorrow. Also, are you open for an interview on thursday next week?",
      sender: "other",
      timestamp: new Date("2024-02-06T15:00:00Z").toISOString(),
    },
    {
      text: "You're welcome! That works for me, just send me an invite.",
      sender: "me",
      timestamp: new Date("2024-02-06T15:05:00Z").toISOString(),
    },
  ],
};

export default chatMessages;
