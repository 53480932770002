// ChatViewPage.js
import React from 'react';
import { FaArrowLeft, FaCog } from 'react-icons/fa';
import chatMessages from '../Data/chatMessagesMock';

const ChatViewPage = ({ currentUser, goBack }) => {
    const messages = chatMessages[currentUser.uuid] || [];

    return (
        <div className="flex flex-col h-screen">
            {/* Fixed Top Section */}
            <div className="fixed top-0 left-0 right-0 z-10">
                <div className="px-4 pt-4 pb-2 flex items-center justify-between bg-background">
                    <FaArrowLeft onClick={goBack} className="ml-2 text-lg text-textColor cursor-pointer" />
                    <div className="flex flex-col items-center">
                        <img src={currentUser.avatar} alt="Avatar" className="mt-2 w-12 h-12 rounded-full object-cover" />
                        <p className="text-[14px] font-semibold text-textColor">{currentUser.name}</p>
                        <p className="text-[12px] font-semibold text-textColor-light">{currentUser.company}</p>
                    </div>
                    <FaCog className="mr-2 text-lg text-textColor" />
                </div>
            </div>

            {/* Scrollable Chat Content */}
            <div className="flex-1 overflow-auto bg-background-two pt-36 p-4">
                {messages.length > 0 ? (
                    messages.map((message, index) => (
                        <div key={index} className={`flex my-4 ${message.sender === 'me' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`p-3 rounded-lg ${message.sender === 'me' ? 'bg-main text-white shadow' : 'bg-white text-textColor shadow'} max-w-[85%] break-words`}>
                                <p className='text-[13px] text-left'>{message.text}</p>
                                <span className={`text-xs block text-right mt-2 ${message.sender === 'me' ? 'text-white/70' : 'text-textColor/50'}`}>{new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className='text-textColor-light'>No messages found.</p> // This will indicate if no messages are available
                )}
            </div>

            {/* Input Section */}
            <div className="p-4 fixed bottom-24 left-0 right-0 z-10">
                <input type="text" className="w-full p-2 border border-gray-300 rounded" placeholder="Type a message..." />
            </div>
        </div>
    );
};
export default ChatViewPage;
