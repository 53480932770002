import React from 'react';
import { FaHouse, FaHeart, FaComments, FaUser, FaSquareCheck } from 'react-icons/fa6';

const BottomNavigation = ({ setCurrentPage, currentPage }) => {
    return (
        <div className="fixed bottom-0 left-0 right-0 px-4 pb-12 w-full bg-background-three flex justify-around items-end">
            <div onClick={() => setCurrentPage('home')} className="flex flex-col items-center">
                <div className={`w-12 h-0.5 mb-5 ${currentPage === 'home' ? 'bg-main' : 'opacity-0'}`}></div>
                <FaHouse className={`text-2xl ${currentPage === 'home' ? 'text-main' : 'text-textColor'} hover:text-main`} />
            </div>
            <div onClick={() => setCurrentPage('matches')} className="flex flex-col items-center">
                <div className={`w-12 h-0.5 mb-5 ${currentPage === 'matches' ? 'bg-main' : 'opacity-0'}`}></div>
                <FaSquareCheck className={`text-2xl ${currentPage === 'matches' ? 'text-main' : 'text-textColor'} hover:text-main`} />
            </div>
            <div onClick={() => setCurrentPage('chat')} className="flex flex-col items-center">
                <div className={`w-12 h-0.5 mb-5 ${(currentPage === 'chat' || currentPage === 'chatView') ? 'bg-main' : 'opacity-0'}`}></div>
                <FaComments className={`text-2xl ${(currentPage === 'chat' || currentPage === 'chatView') ? 'text-main' : 'text-textColor'} hover:text-main`} />
            </div>
            <div onClick={() => setCurrentPage('user')} className="flex flex-col items-center">
                <div className={`w-12 h-0.5 mb-5 ${currentPage === 'user' ? 'bg-main' : 'opacity-0'}`}></div>
                <FaUser className={`text-2xl ${currentPage === 'user' ? 'text-main' : 'text-textColor'} hover:text-main`} />
            </div>
        </div>
    );
};

export default BottomNavigation;