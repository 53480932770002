import React, { useState } from 'react';
import { FaCog, FaPencilAlt } from 'react-icons/fa'; // Icons for the bars

const UserPage = () => {
    const [activeTab, setActiveTab] = useState('OVERVIEW');

    const user = {
        firstName: "John",
        lastName: "Doe",
        photoUrl: "/assets/images/boy.png",
        // other user properties
    };

    const tabs = ['INSIGHTS', 'OVERVIEW', 'PROFILE']; // Replace with actual tab names

    const handleEditClick = (section) => {
        // Logic to handle edit click, possibly open a modal or form
        console.log(`Edit ${section}`);
    };

    const renderContent = () => {
        switch(activeTab) {
            case 'OVERVIEW':
                return (
                    <div className="mx-5">

                        <p className='mt-4 text-left ml-1 text-sm text-textColor/50 font-semibold'>Total</p>
                        <div className="grid grid-cols-2 gap-3 mt-1">
                            <div className="flex items-center px-1 py-4 bg-fourth rounded-xl shadow-lg">
                                <img 
                                    src="/assets/svgs/svg_sent_2.svg"
                                    alt="Icon" 
                                    className="w-10 ml-4 mr-2" 
                                />
                                <div className='flex flex-col'>
                                    <p className="mr-auto text-textColor font-bold text-lg">724</p>
                                    <p className='text-[11px] text-textColor'>Sent applications</p>
                                </div>
                            </div>
                            <div className="flex items-center px-1 py-4 bg-fourth rounded-xl shadow-lg">
                                <img 
                                    src="/assets/svgs/svg_seen_2.svg"
                                    alt="Icon" 
                                    className="w-10 ml-4 mr-2" 
                                />
                                <div className='flex flex-col'>
                                    <p className="mr-auto font-bold text-lg text-textColor">251</p>
                                    <p className='text-[11px]'>Viewed listings</p>
                                </div>
                            </div>
                            <div className="flex items-center px-1 py-4 bg-fourth rounded-xl shadow-lg">
                                <img 
                                    src="/assets/svgs/svg_recieved_2.svg"
                                    alt="Icon" 
                                    className="w-10 ml-4 mr-2" 
                                />
                                <div className='flex flex-col'>
                                    <p className="mr-auto font-bold text-lg text-textColor">47</p>
                                    <p className='text-[11px]'>Offers recieved</p>
                                </div>
                            </div>
                            <div className="flex items-center px-1 py-4 bg-fourth rounded-xl shadow-lg">
                                <img 
                                    src="/assets/svgs/svg_interviews_2.svg"
                                    alt="Icon" 
                                    className="w-10 ml-4 mr-2" 
                                />
                                <div className='flex flex-col'>
                                    <p className="mr-auto font-bold text-lg text-textColor">17</p>
                                    <p className='text-[11px]'>Interviews</p>
                                </div>
                            </div>
                            {/* Repeat for other bars */}
                        </div>

                        <p className='mt-4 text-left ml-1 text-sm text-textColor/50 font-semibold'>Monthly</p>
                        <div className="flex items-center px-1 py-3 mt-1 bg-fourth rounded-xl shadow-lg">
                            <div className='flex flex-col w-full py-4'>
                                
                                <div className="flex items-center mb-6 px-4">
                                    <img 
                                        src="/assets/svgs/svg_sent_2.svg"
                                        alt="Icon" 
                                        className="w-12 mr-3" 
                                    />
                                    <div className='flex flex-col w-full'>
                                        <p className="flex mr-auto font-bold text-[12px]">Applications sent</p>
                                        <div className="flex relative w-full h-2.5 mt-1.5 bg-fifth rounded">
                                            <div className="absolute top-0 h-full bg-main rounded" style={{ width: "70%" }}></div> {/* Progress bar */}
                                        </div>
                                        <p className='flex text-left text-[10px]'>Completed <span className='font-bold mx-1'> 42</span> of <span className='font-bold ml-1'>50</span>.</p> {/* Text right to progress bar */}
                                    </div>
                                </div>

                                <div className="flex items-center mb-6 px-4">
                                    <img 
                                        src="/assets/svgs/svg_matches_2.svg"
                                        alt="Icon" 
                                        className="w-12 mr-3" 
                                    />
                                    <div className='flex flex-col w-full'>
                                        <p className="flex mr-auto font-bold text-[12px]">Matches made</p>
                                        <div className="flex relative w-full h-2.5 mt-1.5 bg-fifth rounded">
                                            <div className="absolute top-0 h-full bg-main rounded" style={{ width: "24%" }}></div> {/* Progress bar */}
                                        </div>
                                        <p className='flex text-left text-[10px]'>41/52</p> {/* Text right to progress bar */}
                                    </div>
                                </div>

                                <div className="flex items-center px-4">
                                    <img 
                                        src="/assets/svgs/svg_talks_2.svg"
                                        alt="Icon" 
                                        className="w-12 mr-3" 
                                    />
                                    <div className='flex flex-col w-full'>
                                        <p className="flex mr-auto font-bold text-[12px]">Interviews conducted</p>
                                        <div className="flex relative w-full h-2.5 mt-1.5 bg-fifth rounded">
                                            <div className="absolute top-0 h-full bg-main rounded" style={{ width: "7%" }}></div> {/* Progress bar */}
                                        </div>
                                        <p className='flex text-left text-[10px]'>41/52</p> {/* Text right to progress bar */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                case 'PROFILE':
                    return (
                        <div className="mx-5 mt-4">
                            {/* Personal Information Section */}
                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Personal Info</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <FaPencilAlt 
                                    onClick={() => handleEditClick('Personal Info')}
                                    className="absolute top-[-10px] right-2 text-xl cursor-pointer"
                                    style={{ color: 'white' }}
                                />
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor"><strong>Name:</strong> {user.firstName} {user.lastName}</p>
                                    <p className="text-textColor"><strong>Email:</strong> john.doe@example.com</p>
                                    {/* Additional personal details */}
                                </div>
                            </div>

                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Skills</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <FaPencilAlt 
                                    onClick={() => handleEditClick('Personal Info')}
                                    className="absolute top-[-10px] right-2 text-xl cursor-pointer"
                                    style={{ color: 'white' }}
                                />
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor">Java, Javascript, C++, GraphQL</p>
                                </div>
                            </div>

                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Experience</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <FaPencilAlt 
                                    onClick={() => handleEditClick('Personal Info')}
                                    className="absolute top-[-10px] right-2 text-xl cursor-pointer"
                                    style={{ color: 'white' }}
                                />
                                <div className="flex flex-col text-left text-[12px]">
                                    <div className='mb-3'>
                                        <p className="font-semibold">Backend Developer <span className='font-regular text-textColor/50'>| Harvard</span></p>
                                        <p className="text-textColor">Developer <span className='text-textColor/50'>| 2023 - Current</span></p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">IT Specialist <span className='font-regular text-textColor/50'>| Texas UNI</span></p>
                                        <p className="text-textColor">Team leader <span className='text-textColor/50'>| 2018 - 2022</span></p>
                                    </div>
                                </div>
                            </div>

                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Education</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <FaPencilAlt 
                                    onClick={() => handleEditClick('Personal Info')}
                                    className="absolute top-[-10px] right-2 text-xl cursor-pointer"
                                    style={{ color: 'white' }}
                                />
                                <div className="flex flex-col text-left text-[12px]">
                                    <div className='mb-3'>
                                        <p className="font-semibold">Computer Science <span className='font-regular text-textColor/50'>| Harvard</span></p>
                                        <p className="text-textColor">Masters degree <span className='text-textColor/50'>| 2017 - 2023</span></p>
                                    </div>
                                    <div>
                                        <p className="font-semibold">Programming <span className='font-regular text-textColor/50'>| Texas UNI</span></p>
                                        <p className="text-textColor">Degree <span className='text-textColor/50'>| 2014 - 2017</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    case 'INSIGHTS':
                    return (
                        <div className="mx-5 mt-4">
                            {/* Application Success Rate Section */}
                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Application Success Rate</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                {/* You can add an edit icon if needed */}
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor mb-3">Your success rate is calculated based on the number of responses you receive from employers.</p>
                                </div>
                            </div>

                            {/* Recommended Skills Section */}
                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Recommended Skills</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor mb-3">Based on your profile and industry trends, consider developing the following skills:</p>
                                    <ul className="list-disc list-inside pl-4 text-textColor">
                                        <li>Python</li>
                                        <li>Project Management</li>
                                        {/* More skills */}
                                    </ul>
                                </div>
                            </div>

                            {/* Job Market Trends Section */}
                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Job Market Trends</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor">Insights into current job market trends in your field:</p>
                                    {/* Display graphical data or bullet points on market trends */}
                                </div>
                            </div>

                            {/* Job Market Trends Section */}
                            <p className='mt-4 text-left ml-1 mb-1 text-sm text-textColor/50 font-semibold'>Personalized Tips</p>
                            <div className="relative bg-third rounded-xl shadow-lg p-4 mb-4">
                                <div className="flex flex-col text-left text-[12px]">
                                    <p className="text-textColor">Insights into current job market trends in your field:</p>
                                    {/* Display graphical data or bullet points on market trends */}
                                </div>
                            </div>
                        </div>

                    );

            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col bg-background text-textColor h-screen">

            {/* Fixed Header */}
            <div className="fixed top-0 w-full bg-background p-4 text-center z-10">
                <img src={user.photoUrl} alt="Profile" className="mt-6 rounded-full h-16 w-16 object-cover mx-auto" />
                <h1 className="text-md text-textColor font-bold my-2">{user.firstName} {user.lastName}</h1>
                <div className="flex justify-center gap-4">
                    {tabs.map(tab => (
                        <div key={tab} onClick={() => setActiveTab(tab)} className={`mt-4 tracking-widest px-4 py-2 text-[12px] font-bold cursor-pointer ${activeTab === tab ? 'text-main' : 'text-textColor/50'} ${activeTab === tab ? 'font-bold' : 'font-semibold'}`}>
                            {tab}
                        </div>
                    ))}
                </div>
                <FaCog className="absolute top-6 right-8 text-2xl text-textColor cursor-pointer" />
            </div>

            {/* Scrollable Content */}
            <div className="pt-52 pb-32 overflow-auto">
                {renderContent()}
            </div>

        </div>
    );
};

export default UserPage;
